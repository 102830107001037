<template>
  <div id="myProfile">
    <div class="content-box">
      <div class="inner">
        <div class="main">
          <div class="title">
            <img class="icon" src="@/assets/images/myProfile/icon.png" alt="" />
            <h3>{{ $t('menu.myProfile') }}</h3>
            <img class="line" src="@/assets/images/myProfile/line.png" alt="" />
          </div>
          <component :is="currentComponent"></component>
          <div class="info">
            <i18n path="myProfile.changeDetails" tag="p">
              <template v-slot:mailTo>
                <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
              </template>
            </i18n>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentComponent() {
      return () => this.$config.pageLoader(this.regulator, 'MyProfile');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/myProfile.scss';
</style>
